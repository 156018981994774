import { contactSubmit } from './contact.js';
import FilterUnits from './services/FilterUnits.js';
import Map from "./services/Map.js";
import Overlay from "./services/Overlay.js";
import Slider from "./services/Slider.js";

if (document.getElementById("contact_form")) {
	contactSubmit();
}

document.addEventListener('DOMContentLoaded', () => {
	const overlay = new Overlay();
	const reviewsSlider = new Slider('[data-slider="reviews"]');
	const singleFacilityMap = new Map('[data-map="section-map"]');
	const locationsMap = new Map('[data-map="locations-map"]');
	const facilitySlider = new Slider('[data-slider="facility-images"]');
	const filterUnits = new FilterUnits('[data-js="facility-rates-node"]');

	[].forEach.call(document.querySelectorAll('.d_click'), (el) => {
		el.addEventListener('click', e => {
			if ('ontouchstart' in document.documentElement || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0) {
				if (!el.hasAttribute('clicked')) {
					e.preventDefault();
					el.setAttribute('clicked', true);
				}
			}
		});
	});

	// Toggle Mobile Menu
	(() => {
		const navOpenBtn = document.querySelector('[data-js="nav-open"]');
		const navCloseBtn = document.querySelector('[data-js="nav-close"]');
		const navMenu = document.querySelector('[data-js="nav-menu"]');

		if (!navMenu) {
			return;
		}

		if (navOpenBtn) {
			navOpenBtn.addEventListener("click", () => {
				navMenu.classList.add("active");
				overlay.show();
				overlay.addListenerOnClose(() => {
					navMenu.classList.remove("active");
				});
			});
		}

		if (navCloseBtn) {
			navCloseBtn.addEventListener("click", () => {
				navMenu.classList.remove("active");
				overlay.hide();
			});
		}
	})();
}, true);

